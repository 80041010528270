<template>
  <div>
    <h1>Timesheet</h1>
    <hr/>
    <h2>{{ userObj.firstName + " " + userObj.lastName }}</h2>
    <div class="payroll-calendar">
      <div class="weeks-container">
        <div
          v-for="(week, weekIndex) in displayedWeeks"
          :key="weekIndex"
          class="week"
          :class="weekIndex === 2 ? 'mt-2' : ''"
        >
          <div class="d-flex justify-content-between" v-if="weekIndex === 0">
            <h2>Prior Pay Period: {{ getPeriodDates(displayedWeeks, 0) }}</h2>
            <h2>Total Hours: {{ getTotalPeriodHours(0) }}</h2>
          </div>
          <div class="d-flex justify-content-between" v-if="weekIndex === 2">
            <h2>Current Pay Period: {{ getPeriodDates(displayedWeeks, 2) }}</h2>
            <h2>Total Hours: {{ getTotalPeriodHours(1) }}</h2>
          </div>
          <hr v-if="weekIndex === 0 || weekIndex === 2"/>
          <div class="days">
            <div
              v-for="day in week"
              :key="day.date"
              class="cursor-pointer"
              @click="goToPersonalSchedule(day.date)"
            >
              <div class="day-header">{{ formatDate(day.date) }}</div>
              <div class="details" :style="{color: getTimeColor(day)}">
                <p v-if="day.hasData">{{ getTimeToData(day) }}</p>
                <p v-else><strong>No Data</strong></p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end pt-1">
            <h3>Total Week Hours: {{ getTotalWeekHours([weekIndex]) }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@core/utils/APIService";

const apiService = new APIService();

export default {
  data() {
    return {
      userObj: {},
      userID: "",
      payrollData: [],
      periods: [],
      currentPeriodIndex: 0,
      constants: {
        baseStartDate: "2024-10-27",  // Base payroll start date
        periodLength: 14,
      },
    };
  },

  computed: {
    displayedWeeks() {
      return this.periods.slice(this.currentPeriodIndex, this.currentPeriodIndex + 2).flat();
    },
  },

  mounted() {
    this.userID = this.$route.params.userID;
    if (this.userID) {
      this.getUser();
    }
    this.getPayrollPeriod();
  },

  methods: {
    getUser() {
      if (!this.userID) {
        return this.showToast('danger', 'top-center', 4000, 'No schedule for unspecified user.');
      }
      apiService.get(`user/${this.userID}/short`).then(res => {
        this.userObj = res.data;
      });
    },

    getTimesheet(start, end) {
      const patch = `timesheets/users?UserId=${this.userID}&fromDate=${start}&toDate=${end}`;
      apiService.get(patch).then(res => {
        this.payrollData = res.data;
        this.generatePeriods();
      });
    },

    createUTCDate(dateStr) {
      const [year, month, day] = dateStr.split('-').map(Number);
      return new Date(Date.UTC(year, month - 1, day));
    },

    formatDate(date) {
      const d = new Date(date);
      const options = { weekday: 'short', month: '2-digit', day: '2-digit', timeZone: 'UTC' };
      return new Intl.DateTimeFormat('en-US', options).format(d);
    },

    formatPeriodDate(date) {
      const d = new Date(date);
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' };
      return new Intl.DateTimeFormat('en-US', options).format(d);
    },

    formatDateToUTC(date) {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    getSunday(date) {
      const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
      const dayOfWeek = utcDate.getUTCDay();
      utcDate.setUTCDate(utcDate.getUTCDate() - dayOfWeek);
      return utcDate;
    },

    calculatePeriodStart(date) {
      const baseDate = this.createUTCDate(this.constants.baseStartDate);
      const periodLength = this.constants.periodLength;
      const diff = Math.floor((date - baseDate) / ((periodLength * 2) * 24 * 60 * 60 * 1000));
      const periodStart = new Date(baseDate);
      periodStart.setUTCDate(baseDate.getUTCDate() + diff * periodLength);
      return this.getSunday(periodStart);
    },

    generatePeriods() {
      const periods = [];
      const currentDate = new Date();
      const utcCurrentDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()));
      const baseStartDate = this.createUTCDate(this.constants.baseStartDate);
      const periodLength = this.constants.periodLength;

      let currentPeriodStart = this.getSunday(baseStartDate);
      while (currentPeriodStart <= utcCurrentDate) {
        const nextPeriodStart = new Date(currentPeriodStart);
        nextPeriodStart.setUTCDate(currentPeriodStart.getUTCDate() + periodLength);
        if (utcCurrentDate < nextPeriodStart) break;
        currentPeriodStart = nextPeriodStart;
      }

      const previousPeriodStart = new Date(currentPeriodStart);
      previousPeriodStart.setUTCDate(currentPeriodStart.getUTCDate() - periodLength);

      [previousPeriodStart, currentPeriodStart].forEach(periodStart => {
        periods.push(this.buildWeeks(periodStart));
      });

      this.periods = periods;
    },

    buildWeeks(periodStart) {
      const weeks = [];
      const startDate = new Date(Date.UTC(
          periodStart.getUTCFullYear(),
          periodStart.getUTCMonth(),
          periodStart.getUTCDate()
      ));

      for (let week = 0; week < 2; week++) {
        const days = [];
        for (let day = 0; day < 7; day++) {
          const date = new Date(startDate);
          date.setUTCDate(startDate.getUTCDate() + week * 7 + day);
          const dateString = date.toISOString().split('T')[0];
          const dayData = this.payrollData.find(entry =>
              entry.date.split('T')[0] === dateString
          );

          days.push({
            date: date.toISOString(),
            hasData: !!dayData,
            approvedTime: dayData?.approvedTime || null,
            notApprovedTime: dayData?.notApprovedTime || null,
            paidTimeOff: dayData?.paidTimeOff || null,
            isDayOff: dayData?.isDayOff || false,
          });
        }
        weeks.push(days);
      }
      return weeks;
    },

    getPayrollPeriod() {
      const currentDate = new Date();
      const utcCurrentDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()));
      const startOfPeriod = this.calculatePeriodStart(utcCurrentDate);
      const endOfPeriod = new Date(startOfPeriod);
      endOfPeriod.setUTCDate(startOfPeriod.getUTCDate() + (this.constants.periodLength * 2) - 1);

      this.getTimesheet(this.formatDateToUTC(startOfPeriod), this.formatDateToUTC(endOfPeriod));
    },

    getPeriodDates(data, startIndex) {
      const start = data[startIndex]?.[0]?.date || '';
      const end = data[startIndex + 1]?.[6]?.date || '';
      return `${this.formatPeriodDate(start)} - ${this.formatPeriodDate(end)}`;
    },

    getTotalPeriodHours(index) {
      let total = 0
      const weeks = this.periods[index] || [];
      const approvedTimes = weeks.flat().map(day => day.approvedTime || 0);
      const out =  total + approvedTimes.reduce((sum, time) => sum + time, 0);
      return out.toFixed(2)
    },

    getTotalWeekHours(index) {
      let total = 0
      const weeks = this.periods.flat(1);
      const approvedTimes = weeks[index].map(day => day.approvedTime || 0);
      const out = total + approvedTimes.reduce((sum, time) => sum + time, 0);
      return out.toFixed(2)
    },

    goToPersonalSchedule(date) {
      const route = this.$router.resolve(`/assignments/user/${this.userID}/${date.substring(0, 10)}`);
      window.open(route.href, '_blank');
    },

    getTimeToData(item) {
      const out = (item.approvedTime || 0) + (item.notApprovedTime || 0) + (item.paidTimeOff || 0);
      return out.toFixed(2)
    },

    getTimeColor(item) {
      return item.notApprovedTime ? '#f6861d' : 'green';
    },
  },
};
</script>

<style scoped>
.payroll-calendar {
  max-width: 80%;
  margin: 0 auto;
  padding: 1rem;
}

h2 {
  padding: 0.5rem 0.5rem 0 0.5rem;
  color: #4a90e2;
  font-weight: bold;
}
h3 {
  font-weight: bold;
}

.weeks-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.week {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0.2rem;
  background-color: #f9f9f9;
}

.days {
  display: flex;
  justify-content: space-between;
}
.day-header {
  border: 1px solid #ddd;
  background: #6187ae;
  color: white;
  width: 9vw;
  text-align: center;
  padding: 0.5rem;
}
.details {
  border: 1px solid #ddd;
  width: 9vw;
  text-align: center;
  padding: 0.5rem;
  text-shadow: 1px 1px 1px black;
  background: #ddd;
}

</style>
