<template>
  <div>
    <PayrollCalendar />
  </div>
</template>

<script>
import PayrollCalendar from './PayrollCalendar.vue';

export default {
  components: {
    PayrollCalendar
  },
};
</script>

<style>
</style>
